import React, { useEffect, useState } from "react";
import config from "../config";
import './post.css'
export default function PostMake(params) {
    var user = params.user
    return (
        <div className="post">
            <div className='author'><img src={`${window.location.protocol}//${config.apiloc}/profilePictures/${user.uid}.png`}/><div className="name">{
                user.badge ? (
                    <div className='badge' style={{color:`${config.getBadge(user.badge).color}`, fontSize:"12px"}}>{config.getBadge(user.badge).prefix}</div>
                ) : null
            }{user.displayname}</div></div>
            <textarea placeholder="Content" id="pluh"/>
            <p className="time">Just Now</p>
            <div className="cbuttons">
                <button onClick={async () => {
                    var headers = new Headers()
                    headers.set("content",document.querySelector("textarea[id='pluh']").value)
                    headers.set("token",window.localStorage.getItem("token"))
                    var response = await fetch(`${window.location.protocol}//${config.apiloc}/createPost`,{headers}).then(res => res.text())
                    if(response !== "No bitch") {
                        window.location.reload()
                    }
                }}>Post</button>
            </div>
        </div>
    )
}