import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import io from "socket.io-client"
import config from './config';
console.log(`%cWARNING!`, "color:red; font-weight:800")
console.log(`%cDo not paste in any code that you did not write yourself. This terminal is ment for the JSocial devs and you can use it maliciously. It is posible for the attacker to steal your user information and take your account. I STRONGLY advise you seek cation when using this terminal.`, "font-family:Sans-serif, font-size:14px")
console.log(`%cIf you find the code from JSocials tips and tricks it has been checked for malicious activity and is safe to use.`, "font-family:Sans-serif, font-size:14px")

const socket = io(config.apiloc)
socket.on("connect",() => {
  console.log("Connected to server as " + socket.id)
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);