export default {
    "apiloc":"api.jwn.social",
    "isDarkmode":() => {
        return localStorage.getItem("theme") == "dark"
    },
    "setTheme":(theme) => {
        if(theme == "dark") {
            localStorage.setItem("theme","dark")
        } else {
            localStorage.removeItem("theme")
        }
    },
    getBadge: (id) => {
        var locationsearch = new URLSearchParams(window.location.search)
        var badges = [
            {
                "id":0,
                "prefix":"",
                "color":"#fff",
                "darkcolor":"#fff"
            },
            {
                "id":1,
                "prefix":"VERIFIED",
                "color":"#52c92e",
                "darkcolor":"#6aff3d"
            },
            {
                "id":2,
                "prefix":"MODERATOR",
                "color":"#ff7d7d",
                "darkcolor":"#ff7d7d"
            },
            {
                "id":3,
                "prefix":"DEVELOPER",
                "color":"#4138f5",
                "darkcolor":"#4138f5"
            },
            {
                "id":4,
                "prefix":"ADMIN",
                "color":"#a60000",
                "darkcolor":"#a60000"
            },
            {
                "id":5,
                "prefix":"SIGMA",
                "color":"#bf0fff",
                "darkcolor":"#bf0fff"
            },
            {
                "id":6,
                "prefix":"CO-OWNER",
                "color":"#0017c7",
                "darkcolor":"#0045c7"
            },
            {
                "id":7,
                "prefix":"OWNER",
                "color":"#479aff",
                "darkcolor":"#479aff"
            },
            {
                "id":8,
                "prefix":"ALPHA",
                "color":"gray",
                "darkcolor":"gray"
            }
        ]
        if(locationsearch.has("dev")) {
            if(locationsearch.get("badge")) id = locationsearch.get("badge")
        }
        var badge = badges.find((ele) => {
            return ele.id == id
        })
        return {
            id:badge.id,
            prefix:badge.prefix,
            color:localStorage.getItem("theme") == "dark" ? badge.darkcolor : badge.color
        }
    }
}
