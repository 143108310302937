import React, {useState} from 'react'
import './login.css'
import config from '../config'
function Page() {
    var [state,setState] = useState("")
    return (
        <div className="center" name="login" style={{height:"100vh"}}>
            <div className="loginscreen">
                <h1>Login</h1>
                <input placeholder='Username/Email' uname="true" type='email'/>
                <input placeholder='Password' pword="true" type='password'/>
                <div className="buttons">
                    <button>Frogot Password</button>
                    <button onClick={() => {
                        var headers = new Headers()
                        headers.append("uname",document.querySelector("input[uname]").value)
                        headers.append("pword",btoa(document.querySelector("input[pword]").value))
                        fetch(`${window.location.protocol}//${config.apiloc}/token`,{headers}).then(res => res.text()).then(tok => {
                            if(tok == "") {
                                setState("Invalid username or password")
                            } else {
                                setState("Logging in")
                                localStorage.setItem("token",tok)
                                window.location.replace("/")
                            }
                        })
                    }}>Login</button> 
                </div>
                <p>{state}</p>
            </div>
        </div>
    )
}
export default Page