import React, {useEffect} from 'react'
function Page() {
    useEffect(() => {
        window.localStorage.removeItem("token")
        window.location.replace("/")
    })
    return (
        <p>Logging out</p>
    )
}
export default Page