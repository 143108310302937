import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nav from './components/nav';
import Index from './pages/index';
import Messenger from './pages/messenger';
import UserProfle from './pages/userprofile';
import Login from './pages/login';
import Signup from './pages/signup';
import Logout from './pages/logout';
import Auth from './pages/auth';
import config from './config';
function App() {
  return (
    <>
      <Nav />
      <main theme={config.isDarkmode() ? "dark" : "light"}>
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<Index/>}/>
          {/* <Route path='/messages' element={<Messenger/>}/> */}
          <Route path='/profile/:username' element={<UserProfle/>}/>
          <Route path='/authentication' element={<Auth/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/logout' element={<Logout/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='*' element={<h1>Error 404</h1>}/>
        </Routes> 
      </BrowserRouter>
      </main>
      {/* <footer>
        <p>Project JSocial™ Beta 4.0.0</p>
        <p>Owned by <a href='https://justwhatever.net'>JustWhatever</a></p>
        <p>JSocial © 2022-{new Date().getFullYear()}</p>
      </footer> */}
    </>
  );
}

export default App;
