import React from 'react'
class Page extends React.Component {
    render() {
        return (
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
                <h2>Comming soon</h2>
            </div>
        )
    }
}
export default Page