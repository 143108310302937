import React, { useState } from 'react'
import './signup.css'
import config from '../config'
export default function Page() {
    var [message,setMessage] = useState("")
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
            <div className="signupscreen">
                <h1>Sign up</h1>
                <input placeholder='Username (public)' imsofuckingdonewiththisbullshit="username"/>
                <input placeholder='Name (public)' imsofuckingdonewiththisbullshit="name"/>
                <input placeholder='Email (private)' type='email' imsofuckingdonewiththisbullshit="email"/>
                <input placeholder='Password (private)' type='password' imsofuckingdonewiththisbullshit="password"/>
                <div className="check">
                    <input type='checkbox' imsofuckingdonewiththisbullshit="abletosignup"/>
                    <label for="a">I achnolage that this site is in an expreimental state and is not finished. Some data from this site may be lost.</label>
                </div>
                <p>{message}</p>
                <button onClick={async () => {
                    var uname = document.querySelector("input[imsofuckingdonewiththisbullshit='username']")
                    var name = document.querySelector("input[imsofuckingdonewiththisbullshit='name']")
                    var email = document.querySelector("input[imsofuckingdonewiththisbullshit='email']")
                    var pword = document.querySelector("input[imsofuckingdonewiththisbullshit='password']")
                    var signup = document.querySelector("input[imsofuckingdonewiththisbullshit='abletosignup']")
                    if(signup.value) {
                        var data = {
                            username:uname.value,
                            displayname:name.value,
                            email:email.value,
                            password:pword.value
                        }
                        var signuprequrest = await fetch(`${window.location.protocol}//${config.apiloc}/signup/${btoa(JSON.stringify(data))}`).then(res => res.json())
                        if(signuprequrest.completed) {
                            window.localStorage.setItem("token",signuprequrest.token)
                            window.location.replace("/profile/" + uname.value.toLowerCase().replaceAll(" ","_"))
                        } else {
                            setMessage(signuprequrest.reason)
                        }
                    }
                    
                }}>Signup</button>
            </div>
        </div>
    )
}