import React, { useEffect, useState } from "react";
import config from "../config";
import './post.css'
export default function Post(params) {
    var postid = params.id
    var [user, setUser] = useState({})
    var [post, setPost] = useState({})
    useEffect(() => {
        fetch(`${window.location.protocol}//${config.apiloc}/post/${postid}`).then(res=>res.json()).then(res => {
            setPost(res)
            fetch(`${window.location.protocol}//${config.apiloc}/getUser/${res.creator}`).then(res3=>res3.json()).then(res2 => {
                setUser(res2)
            })
        })
    },[])
    function wasthisonthesameday(d1,d2) {
        var date1 = d1
        var date2 = d2
        return date1.setHours(0,0,0,0) == date2.setHours(0,0,0,0)
    }
    function formatDate(int) {
        if(int < 0) {
            return "This post was transfered from JSocial 1.0 Date can not be found"
        }
        var date = new Date(int)
        var datev = new Date(int)
        var currentvDate = new Date()
        var currentDate = new Date()
        if(wasthisonthesameday(datev,currentvDate)) {
            var ago = currentDate - int
            var hoursago = Math.floor(ago / 1000 / 60 / 60)
            ago -= hoursago * 1000 * 60 * 60
            var minuteago = Math.floor(ago / 1000 / 60)
            ago -= minuteago * 1000 * 60
            var secondago = Math.floor(ago / 1000)
            ago -= secondago * 1000
            if(hoursago > 0) {
                return `${hoursago} hour${hoursago > 1 ? "s" : ""} ago`
            } else if(minuteago > 0) {
                return `${minuteago} minute${minuteago > 1 ? "s" : ""} ago`
            } else if(secondago > 0) {
                return `${secondago} second${secondago > 1 ? "s" : ""} ago`
            } else {
                return `Error while formating date`
            }
        } else {
            var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
            var daysoftheweek = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
            return `${daysoftheweek[date.getDay()]} ${months[date.getMonth()]} ${date.getDay()} ${date.getFullYear()}`
        }
    }
    return (
        <div className="post">
            <div className='author'><img src={`${window.location.protocol}//${config.apiloc}/profilePictures/${user.uid}.png`}/><div className="name">{
                    user.badge ? (
                        <div className='badge' style={{color:`${config.getBadge(user.badge).color}`, fontSize:"12px"}}>{config.getBadge(user.badge).prefix}</div>
                    ) : null
                }{user.displayname}</div></div>
                <p className="content">
                    {post.content}
                </p>
                <p className="time">{formatDate(Number(post.time))}</p>
                <div className="buttons">
            </div>
        </div>
    )
}