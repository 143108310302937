import React, { useEffect, useState } from "react";
import "./nav.css";
import config from "../config";
import BOC from "../JSocial-BundleOfColor.svg";
import WHITE from "../JSocial-White.svg";
import BLACK from "../JSocial-Black.svg";
function Nav() {
  var [state, setState] = useState({});
  useEffect(() => {
    if(localStorage.getItem("token")) {
        fetch(`${window.location.protocol}//${config.apiloc}/getFullUser/${localStorage.getItem("token")}`).then(res=>res.json()).then(res => {
            setState(res)
        })
    }
    
  },[]);
  return (
    <nav>
      <div className="brand">
        <a href="/">
          <img src={BOC} />
        </a>
      </div>
      <div className="links">
        {localStorage.getItem("token") ? (
          <>
            <a href="/messages">Messenger</a>
            <a href={`/profile/${state.username}`}>My Profile</a>
            <a href='/logout'>Logout</a>
          </>
        ) : (
          <>
            <a href="/login">Login</a>
            <a href="/signup">Signup</a>
          </>
        )}
      </div>
    </nav>
  );
}
export default Nav;
