import React, { useEffect, useState } from 'react'
import config from "../config";
import './userprofile.css'
import Post from '../components/post';
import PostMake from '../components/createpost';
function Page() {
    var [user,setUser] = useState({
        displayname:"",
        username:"",
        email:"",
        token:"",
        followers:[],
        following:[],
        lastonline:0,
        password:"",
        badge:0,
        posts:[],
        time:0,
        uid:""
    })
    var [meuser,setmeUser] = useState({
        displayname:"",
        username:"",
        email:"",
        token:"",
        followers:[],
        following:[],
        lastonline:0,
        password:"",
        posts:[],
        time:0,
        badge:0,
        uid:""
    })
    var [posts, setPosts] = useState([])
    useEffect(() => {
        fetch(`${window.location.protocol}//${config.apiloc}/getUserFromUsername/${window.location.pathname.split("/")[2]}`).then(res=>res.json()).then(res => {
            setUser(res)
            fetch(`${window.location.protocol}//${config.apiloc}/posts/${res.uid}`).then(res3=>res3.json()).then(res2 => {
                setPosts(res2)
            })
        })
        if(window.localStorage.getItem("token")) {
            fetch(`${window.location.protocol}//${config.apiloc}/getFulluser/${window.localStorage.getItem("token")}`).then(res=>res.json()).then(res => {
                setmeUser(res)
            })
        }
       
    },[])
    var badge = {
        prefix: ``,
        color: ``
    }
    if(user.badge) {
        badge = {
            prefix: config.getBadge(user.badge).prefix,
            color: config.getBadge(user.badge).color
        }
    }
    const suffexes = [
        {
            type:"none",
            suffex:"",
            color:"#FFFFFF"
        },
        {
            type:"plus",
            suffex:"+",
            color:"#FFD046"
        },
        {
            type:"premium",
            suffex:"+",
            color:"#A2FFFF"
        },
        {
            type:"extended",
            suffex:"✩",
            color:"#A882DD"
        }
    ]
    var prembadge = {
        suffex: ``,
        color: ``
    }
    if(user.premium) {
        prembadge = {
            suffex: suffexes.find((element) => element.type == user.premium).suffex,
            color: suffexes.find((element) => element.type == user.premium).color
        }
    }
    return (
        <div className="uprof">
            <div className="profile">
                <img src={`${window.location.protocol}//${config.apiloc}/profilePictures/${user.uid}.png`} />
                <div className="body">
                    <h1 style={{display:"flex",alignItems:"flex-start",fontFamily:"Montserrat"}}>{user.displayname}<span style={{color:prembadge.color,fontSize:"30px",marginLeft:"4px"}}>{prembadge.suffex}</span></h1>
                    {
                        user.badge ? (
                            <p style={{color:badge.color,fontSize:"24px"}}>{badge.prefix}</p>
                        ) : null
                    }
                    <p style={{color:"gray",fontFamily:"Poppins"}}>@{user.username}</p>
                    <p style={{fontSize:"17px", fontWeight:600, fontFamily:"Source Code Pro"}}>Following: {user.following.length} Followers: {user.followers.length}</p>
                    <p style={{fontFamily:""}}>{user.bio}</p>
                    <div className="userprofile-buttons">
                        {
                            meuser.uid == user.uid ? (
                                <button className='blue'>Edit Profile</button>
                            ) : (
                                <>
                                    <button className='red' onClick={() => {
                                        if(window.localStorage.getItem("token")) {
                                            // Follow code
                                        } else {
                                            window.location.replace("/login")
                                        }
                                    }}>{meuser.following.includes(user.uid) ? "Unfollow" : "Follow"}</button>
                                    <button className='dark_red' onClick={() => {
                                        if(window.localStorage.getItem("token")) {
                                            // Block code
                                        } else {
                                            window.location.replace("/login")
                                        }
                                    }}>Block</button>
                                    <button className='blue' onClick={() => {
                                        if(window.localStorage.getItem("token")) {
                                            // Message code
                                        } else {
                                            window.location.replace("/login")
                                        }
                                    }}>Message</button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="posts">
                {
                    meuser.uid == user.uid ? (
                        <PostMake user={meuser}/>
                    ) : null
                }
                {posts.map((e) => {
                    return (
                        <Post id={e.postid} />
                    )
                })}
            </div>
        </div>
    )
}
export default Page