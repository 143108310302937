import React, {useEffect,useState} from 'react'
import config from '../config'
import './auth.css'
function Auth() {
    var [user,setUser] = useState({
        displayname:"",
        username:"",
        email:"",
        token:"",
        followers:[],
        following:[],
        lastonline:0,
        password:"",
        badge:0,
        posts:[],
        time:0,
        uid:""
    })
    useEffect(() => {
        if(window.localStorage.getItem("token")) {
            fetch(`${window.location.protocol}//${config.apiloc}/getFulluser/${window.localStorage.getItem("token")}`).then(res=>res.json()).then(res => {
                setUser(res)
            })  
        } else {
            window.location.replace("/login")
        }
       
    },[])
    var urlParams = new URLSearchParams(window.location.search)
    var url = new URL(urlParams.get("redirect"))
    return (
        <div className='loginAuthMenu'>
            <div className='container'>
                <img src={`https://api.statvoo.com/favicon/${url.hostname}`} />
                <p>Do you want to login with <span style={{color:"lightblue"}}>{url.hostname}</span>?</p>
                <h2 style={{fontSize:16,fontWeight:"600"}}>{url.hostname} will have access to your</h2>
                <h5 style={{fontSize:14,fontWeight:"400"}}>Username</h5>
                <h5 style={{fontSize:14,fontWeight:"400"}}>Email</h5>
                <h5 style={{fontSize:14,fontWeight:"400"}}>Display Name</h5>
                <h5 style={{fontSize:14,fontWeight:"400"}}>Badge</h5>
                <div className="buttons">
                    <button onClick={() => {
                        url.searchParams.append("auth","canceled")
                        window.location.replace(url.href);
                    }}>Cancel</button>
                    <button onClick={() => {
                        var headers = new Headers()
                        headers.append("content","username,email,displayname,badge,uid")
                        fetch(`${window.location.protocol}//${config.apiloc}/getAuthKey/${window.localStorage.getItem("token")}`,{headers}).then(res=>res.text()).then(key => {
                            url.searchParams.append("auth",key)
                            window.location.replace(url.href);
                        })
    
                    }}>Continue</button>
                </div>
            </div>

        </div>
        
    )
}
export default Auth